import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ModalHelper } from "@/components/modals/ModalHelper";
import { Carousel, Slide } from "vue-carousel";
import { endianness } from "os";

@Component({ components: { Carousel, Slide } })
export default class Tutorial extends Vue {
  public images: string[] = []

  public hideTutorial() {
    ModalHelper.HideModal();
  }

  public mounted() {
      this.images = [
        "assets/imgs/tutorial/B0_home.png",
        "assets/imgs/tutorial/B0_homeclic.png",
        "assets/imgs/tutorial/B1_level1.png",
        "assets/imgs/tutorial/B1_level1clic.png",
        "assets/imgs/tutorial/B2_level2.png",
        "assets/imgs/tutorial/B2_level2clic.png",
        "assets/imgs/tutorial/B3_popup.png",
        "assets/imgs/tutorial/B3_popupclic.png",
        "assets/imgs/tutorial/B4a_compare.png",
        "assets/imgs/tutorial/B4a_compareclic.png",
        "assets/imgs/tutorial/B4b_compare.png",
        "assets/imgs/tutorial/B4b_compareclic.png",
        "assets/imgs/tutorial/B5_switch_summary.png",
        "assets/imgs/tutorial/B5_switch_summaryclic.png",
        "assets/imgs/tutorial/B6_switch_compare.png",
        "assets/imgs/tutorial/B6_switch_compareclic.png",
        "assets/imgs/tutorial/B7_closecompare.png",
        "assets/imgs/tutorial/B8a_fullext.png",
        "assets/imgs/tutorial/B8b_reset.png",
        "assets/imgs/tutorial/C2_search.png",
        "assets/imgs/tutorial/C3_dataset.png",
        "assets/imgs/tutorial/C4_options.png",
      ];
  }
}
