import Vue from "vue";
import Component from "vue-class-component";
import { MapHelper } from "@/app_modules/MapHelper";
import { geometriesStoreActions, configurationsStoreActions, kpatlasStoreActions } from "@/store";

@Component({})
export default class HomeWidget extends Vue {
  public currentMap: any;
  public async mounted() {
    this.currentMap = await MapHelper.getCurrentMap();
  }

  public click() {
    configurationsStoreActions.cleanIndicatorGroup();
    configurationsStoreActions.setCompare(false);
    kpatlasStoreActions.setShowSubnationalData(false);
    geometriesStoreActions.cleanSelectedCountry();
    geometriesStoreActions.cleanSelectedRegion();
    geometriesStoreActions.cleanSelectedSite();
    this.currentMap.goToHome();
  }
}
