import Vue from "vue";
import Component from "vue-class-component";
import { configurationsStoreGetters } from "@/store";

import { ModalHelper } from "@/components";

import LandingPage from "@/components/landing-page/landing-page.vue";
import Tutorial from "@/components/tutorial/tutorial.vue";

@Component({})
export default class OtherWidget extends Vue {
  public showMenu: boolean = false;

  get config() {
    return configurationsStoreGetters.config();
  }

  get abstract(): KpAtlas.Abstract {
    if (this.config) {
      return this.config.abstract;
    }
    return null;
  }

  get leafFeedback() {
    return this.config.abstract.leaveFeedback;
  }

  public openLandingPage() {
    ModalHelper.ShowModalAsync(LandingPage).then((completed) => { }, (e) => { });
  }

  public openTutorial() {
    ModalHelper.ShowModalAsync(Tutorial).then((completed) => { }, (e) => { });
  }
}
