import Vue from "vue";
import Component from "vue-class-component";
import { MapHelper } from "@/app_modules/MapHelper";

@Component({})
export default class FullExtentWidget extends Vue {
  public currentMap: any;
  public async mounted() {
    this.currentMap = await MapHelper.getCurrentMap();
  }

  public click() {
    this.currentMap.goToHome();
  }
}
