import Vue from "vue";
import Component from "vue-class-component";

import { messageService, Factory, MessageService } from "@/services/_base";
import { geometriesStoreGetters, geometriesStoreActions, configurationsStoreGetters, configurationsStoreActions, kpatlasStoreActions } from "@/store";
import { Watch } from "vue-property-decorator";
import { MapHelper } from "@/app_modules/MapHelper";

@Component({})
export default class DetailPanel extends Vue {
  public currentComponent = null;
  public extended: boolean = false;

  public created() {
    messageService.subscribeToChannel("detail-panel-added", (n) => {
      this.currentComponent = n[0];
      this.extended = n[1] || false;
    });
    messageService.subscribeToChannel("detail-panel-removed", (n) => {
      this.currentComponent = null;
    });
  }

  get selectedCountry(): __esri.Graphic {
    return geometriesStoreGetters.getSelectedCountry();
  }

  get compare(): boolean {
    return configurationsStoreGetters.compare();
  }

  get title() {
    if (this.selectedCountry) {
      return this.selectedCountry.attributes.name;
    }
    return null;
  }

  public close() {
    geometriesStoreActions.cleanSelectedCountry();
    kpatlasStoreActions.setShowSubnationalData(false);
    this.currentComponent = null;
  }

  public exportData() {
    Factory.Get(MessageService).publishToChannel("export-data");
  }
}
