// tslint:disable: callable-types
import Vue from "vue";
import { Esri } from "@/esriMap";
import { Deferred } from "@/services/_base/Deferred";
import { messageService } from "@/services/_base";

export interface OnDrawingComplete {
  (graphic: __esri.Graphic): void;
}
export interface OnDrawingReset {
  (): void;
}

export interface GraphicSelectFunction {
  (graphic: __esri.Graphic): boolean;
}

export interface OnGraphicClick {
  (graphic: __esri.Graphic): boolean;
}

export interface OnMapClick {
  (event): void;
}
export interface OnMapHitTest {
  (event, results: __esri.Graphic[]): void;
}

export class MapHelper {
  public static lockMapZoomAndPan: boolean = false;
  public static CurrentMap: {
    map: __esri.Map;
    mapView: __esri.MapView;
    sketcher: __esri.SketchViewModel;
    enableDraw: (arg0: __esri.Graphic, arg1: OnDrawingComplete) => void;
    resetDraw: () => void;
    disableDraw: () => void;
    addDrawTool: (arg0: OnDrawingComplete) => void;
    currentMapOverlay: any;
  };

  public static Sketcher: any = null;

  public static clearMap() {
    MapHelper.mapreadyPromise = new Deferred();
    MapHelper.CurrentMap = null;
  }

  public static setCurrentMap(map: any) {
    MapHelper.CurrentMap = map;
    MapHelper.Sketcher = map.sketcher;
    MapHelper.mapreadyPromise.resolve();

    // register map events.
    map.mapView.on("click", (e) => {
      const screenPoint = {
        x: e.x,
        y: e.y,
      };

      if (MapHelper.nextClick) {
        MapHelper.nextClick.callback(e);
        if (!MapHelper.nextClick.persistence) {
          MapHelper.nextClick = null;
        }
      }

      if (MapHelper.hitTestNextClick) {
        if (MapHelper.hitTestNextClick.stopPropagation) {
          e.stopPropagation(); // stoppo la propagazione dell'evento click per impedire l'apertura del popup
          map.mapView.popup.visible = false; // nascondo il popup tante le volte fosse visibile
        }
        map.mapView
          .hitTest(screenPoint)
          .then((response: __esri.HitTestResult) => {
            const results = response.results.map((r) => r.graphic);
            MapHelper.hitTestNextClick.callback(e, results);
            if (!MapHelper.hitTestNextClick.persistence) {
              MapHelper.hitTestNextClick = null;
            }
          });
      }
    });

    map.mapView.on("double-click", (e) => {
      const screenPoint = {
        x: e.x,
        y: e.y,
      };

      if (MapHelper.nextDoubleClick) {
        MapHelper.nextDoubleClick.callback(e);
        if (!MapHelper.nextDoubleClick.persistence) {
          MapHelper.nextDoubleClick = null;
        }
      }

      if (MapHelper.hitTestNextDoubleClick) {
        if (MapHelper.hitTestNextDoubleClick.stopPropagation) {
          e.stopPropagation(); // stoppo la propagazione dell'evento click per impedire l'apertura del popup
          map.mapView.popup.visible = false; // nascondo il popup tante le volte fosse visibile
        }
        map.mapView
          .hitTest(screenPoint)
          .then((response: __esri.HitTestResult) => {
            const results = response.results.map((r) => r.graphic);
            MapHelper.hitTestNextDoubleClick.callback(e, results);
            if (!MapHelper.hitTestNextDoubleClick.persistence) {
              MapHelper.hitTestNextDoubleClick = null;
            }
          });
      }
    });

    map.mapView.on("layerview-create", (event) => {
      MapHelper.layersok.push(event.layer.id);
    });

    // devo invocare la funzione che renderizza il contenuto ogni volta che cambio feature altrimenti non viene cambiato il valore della prop al componente vue
    map.mapView.popup.watch("selectedFeature", (newValue, oldValue) => {
      if (
        newValue &&
        newValue.popupTemplate &&
        newValue.popupTemplate.content instanceof Function
      ) {
        newValue.popupTemplate.content();
      }
    });
  }

  public static async getCurrentMap() {
    await MapHelper.mapreadyPromise.promise;
    return MapHelper.CurrentMap;
  }

  public static async Redraw() {
    const mw = (await MapHelper.getCurrentMap()).mapView;
    mw.center = [mw.center.longitude, mw.center.latitude] as any;
  }

  public static async GetLayerById(id: string): Promise<any> {
    const map = (await this.getCurrentMap()).map;
    return map.allLayers.find((l) => l.id === id);
  }

  public static async GetLayerByName(name: string) {
    const map = (await this.getCurrentMap()).map;
    return map.allLayers.find((l: any) => l.name === name);
  }

  public static async GetLayerView(id: string): Promise<__esri.LayerView> {
    const layer = await this.GetLayerById(id);
    return await MapHelper.CurrentMap.mapView.whenLayerView(layer);
  }

  public static async WaitMapReady(): Promise<void> {
    await this.getCurrentMap();
  }

  public static async WaitLayersReady(...names: string[]) {
    for (const k in names) {
      if (names.hasOwnProperty(k)) {
        await MapHelper.WaitLayerReady(names[k]);
      }
    }
  }

  public static async WaitLayerReady(name: string): Promise<void> {
    const deferred = new Deferred();

    const mv = (await this.getCurrentMap()).mapView;

    const result = MapHelper.layersok.indexOf(name) >= 0;
    if (result) {
      deferred.resolve();
      return;
    }

    const cancellation = mv.on("layerview-create", (event) => {
      if (event.layer.id === name) {
        deferred.resolve();
      }
    });

    await deferred.promise;
    cancellation.remove();
  }
  public static async enableDrawing(onCompleted: OnDrawingComplete) {
    await (await this.getCurrentMap()).addDrawTool(onCompleted);
  }
  public static async startDrawing(graphic: __esri.Graphic = null, onCompleted: OnDrawingComplete = null) {
    await (await this.getCurrentMap()).enableDraw(graphic, onCompleted);
  }
  public static async resetDrawing() {
    await (await this.getCurrentMap()).resetDraw();
  }
  public static async disableDraw() {
    await (await this.getCurrentMap()).disableDraw();
  }

  public static async showGraphic(g: __esri.Graphic) {
    (await this.getCurrentMap()).mapView.graphics.add(g);
  }
  public static async addGraphic(geometry: __esri.Geometry, symbol: __esri.Symbol, attributes: any) {
    const graphic = await Esri.Graphic({
      attributes,
      geometry,
      symbol,
    }) as __esri.Graphic;
    (await this.getCurrentMap()).mapView.graphics.add(graphic);
    return graphic;
  }

  public static async removeGraphic(g: __esri.Graphic) {
    (await this.getCurrentMap()).mapView.graphics.remove(g);
  }

  public static async showMapOverlay(component: HTMLElement) {
    if (component) {
      component.remove();
    }

    (await this.getCurrentMap()).currentMapOverlay = component;
  }

  public static async removeMapOverlay() {
    (await this.getCurrentMap()).currentMapOverlay = null;
  }

  public static async addLayer(
    layer: __esri.Layer,
    index: number = null,
    groupId: string = null,
    groupName: string = null,
  ) {
    if (groupId) {
      if (!MapHelper.grouplayers.has(groupId)) {
        const gl = await Esri.Layers.GroupLayer({
          id: groupId,
          title: groupName || groupId,
        } as __esri.GroupLayerProperties);
        // E' necessario un doppio controllo a causa delle asincronicità di alcune inizializzazioni.
        if (!MapHelper.grouplayers.has(groupId)) {
          MapHelper.grouplayers.set(groupId, gl);
        }
        (await this.getCurrentMap()).map.add(
          MapHelper.grouplayers.get(groupId),
        );
      }

      MapHelper.grouplayers.get(groupId).add(layer, index);
    } else { (await this.getCurrentMap()).map.add(layer, index); }
  }

  public static async removeLayer(layer: __esri.Layer) {
    const map = (await this.getCurrentMap()).map;
    if (MapHelper.grouplayers.has(layer.id)) {
      MapHelper.grouplayers.delete(layer.id);
    }
    map.remove(layer);
  }

  public static async goToGeometry(
    geometry: __esri.Geometry,
    zoom: number = 18,
  ) {

    if (MapHelper.lockMapZoomAndPan) {
      return;
    }

    if (geometry.extent && zoom < 0) {
      (await this.getCurrentMap()).mapView.goTo(geometry.extent.expand(1.2),
        { animate: true },
      );
    } else {
      (await this.getCurrentMap()).mapView.goTo(
        { target: geometry, zoom },
        { animate: true },
      );
    }
  }

  public static async goToLayer(layername: string, extend: boolean = true) {
    if (MapHelper.lockMapZoomAndPan) { return; }
    const layer = await MapHelper.GetLayerByName(layername);

    if (layer) {
      const lv = (await (await this.getCurrentMap()).mapView.whenLayerView(
        layer,
      )) as any;

      const extent = await Esri.Geometry.ExtentOf(
        lv.layer.graphics.items.map((g) => g.geometry),
      );
      if (extent) {
        (await this.getCurrentMap()).mapView.goTo(
          extend ? extent.expand(1.2) : extent,
          { animate: false },
        );
      }
    }
  }

  public static async highlightGeometry(geometry: __esri.Geometry, symbol: any, zoomTo: boolean = false, additive: boolean = false) {
    if (!additive) {
      await this.removeHighlight();
    }

    const toHighlight = await Esri.Graphic({
      geometry,
      symbol,
    });

    this.multiHightlighted.push(toHighlight);
    await MapHelper.showGraphic(toHighlight);

    if (zoomTo) {
      await MapHelper.goToGeometry(geometry);
    }

    return toHighlight;

  }

  public static async highlightGraphics(highlights: Array<{ geometry: __esri.Geometry; symbol: __esri.Symbol }>, additive: boolean = false) {

    if (!additive) {
      await this.removeHighlight();
    }

    for (const key in highlights) {
      if (highlights.hasOwnProperty(key)) {
        const g = highlights[key];

        const highlight = await Esri.Graphic(g);

        this.multiHightlighted.push(highlight);

        await MapHelper.showGraphic(highlight);
      }
    }

    return this.multiHightlighted;
  }

  public static async highlightGeometries(geometries: __esri.Geometry[], symbol: __esri.Symbol) {

    await this.removeHighlight();

    for (const key in geometries) {
      if (geometries.hasOwnProperty(key)) {
        const g = geometries[key];

        const highlight = await Esri.Graphic({
          geometry: g,
          symbol,
        });

        this.multiHightlighted.push(highlight);

        await MapHelper.showGraphic(highlight);
      }
    }

    return this.multiHightlighted;
  }

  public static async removeHighlight() {
    // if (this.hightlighted) {
    //   try {
    //     MapHelper.removeGraphic(this.hightlighted);
    //   } catch (err) {
    //     console.error(err);
    //   }
    //   this.hightlighted = null;
    // }
    if (this.multiHightlighted && this.multiHightlighted.length > 0) {
      for (const g in this.multiHightlighted) {
        if (this.multiHightlighted.hasOwnProperty(g)) {
          try {
            if (this.multiHightlighted.hasOwnProperty(g)) {
              const geom = this.multiHightlighted[g];
              MapHelper.removeGraphic(geom);
            }
          } catch (err) {
            console.error(err);
          }
        }
      }
      this.multiHightlighted = [];
    }
  }

  public static async onNextClick(callback: OnMapClick, persistence: boolean = false, stopPropagation: boolean = true) {
    MapHelper.nextClick = { callback, persistence, stopPropagation };
  }
  public static async onNextClickClean() {
    MapHelper.nextClick = null;
  }

  public static async onNextClickHitTest(callback: OnMapHitTest, persistence: boolean = false, stopPropagation: boolean = true) {
    MapHelper.hitTestNextClick = { callback, persistence, stopPropagation };
  }

  public static async onNextClickHitTestClean() {
    MapHelper.hitTestNextClick = null;
  }

  public static async onNextDoubleClick(callback: OnMapClick, persistence: boolean = false, stopPropagation: boolean = true) {
    MapHelper.nextDoubleClick = { callback, persistence, stopPropagation };
  }
  public static async onNextDoubleClickClean() {
    MapHelper.nextDoubleClick = null;
  }

  public static async onNextDoubleClickHitTest(callback: OnMapHitTest, persistence: boolean = false, stopPropagation: boolean = true) {
    MapHelper.hitTestNextDoubleClick = { callback, persistence, stopPropagation };
  }
  public static async onNextDoubleClickHitTestClean() {
    MapHelper.hitTestNextDoubleClick = null;
  }

  public static addCustomActionToLayer(layer: __esri.Layer, actionid: string, actiontitle: string, actioniconclass: string, action: () => void) {
    const l = layer as any;
    if (!l.customActions) { l.customActions = []; }
    l.customActions.push({
      title: actiontitle,
      id: l.id + "_" + actionid,
      className: actioniconclass,
      action,
    });
  }

  public static async ShowLayer(layerid: string) {
    const result = (await this.GetLayerById(layerid)) as __esri.Layer;
    if (result) { result.visible = true; }
  }

  public static async HideLayer(layerid: string) {
    const result = (await this.GetLayerById(layerid)) as __esri.Layer;
    if (result) { result.visible = false; }
    if (this.CurrentMap.mapView.popup.visible) {
      this.CurrentMap.mapView.popup.close();
    }
  }

  public static async ShowAllLayersBut(...but: string[]) {
    await this.WaitMapReady();
    const layers = this.CurrentMap.map.allLayers;
    if (layers) {
      layers.forEach((layer) => {
        if (but.indexOf(layer.id) >= 0) {
          layer.visible = false;
          return;
        }
        layer.visible = true;
      });
    }
  }

  public static async HideAllLayersBut(...but: string[]) {
    await this.WaitMapReady();
    if (this.CurrentMap.mapView.popup.visible) {
      this.CurrentMap.mapView.popup.close();
    }
    const layers = this.CurrentMap.map.allLayers;

    if (layers) {
      layers.forEach((layer) => {
        if (but.indexOf(layer.id) >= 0) {
          layer.visible = true;
          return;
        }

        if (layer.type !== "group" && layer.type !== "tile") {
          layer.visible = false;
        }
      });
    }
  }

  public static async HideMap() {
    messageService.publishToChannel("resize-map", 3);
  }

  public static async ShowMapSmall() {
    messageService.publishToChannel("resize-map", 2);
  }

  public static async ShowMapMedium() {
    messageService.publishToChannel("resize-map", 1);
  }

  public static async ShowMapFull() {
    messageService.publishToChannel("resize-map", 0);
  }

  private static _popupTemplate: any = null;
  public static CreatePopupTemplate(component) {
    if (MapHelper._popupTemplate) {
      MapHelper._popupTemplate.$destroy();
    }
    MapHelper._popupTemplate = new (Vue.extend(component))();
    return MapHelper._popupTemplate.$mount();
  }

  public static CreateWidgetTemplate(component) {
    const comp = new (Vue.extend(component))();
    return comp.$mount();
  }

  public static async SetGraphicPopupTemplate(component: Vue, title: string | (() => string), contentFunction: OnComponentCreated) {
    const pp = await Esri.PopupTemplate({
      title,
      content: () => {
        if (contentFunction) {
          contentFunction(component);
        }
        return component.$el;
      },
    });
    return pp;
  }

  private static mapreadyPromise: Deferred<any> = new Deferred();
  private static layersok: string[] = new Array<string>();
  private static grouplayers: Map<string, __esri.GroupLayer> = new Map<string, __esri.GroupLayer>();
  // private static hightlighted: __esri.Graphic;
  private static multiHightlighted: __esri.Graphic[] = [];
  private static nextClick: { callback: OnMapClick; persistence: boolean; stopPropagation: boolean } = null;
  private static hitTestNextClick: { callback: OnMapHitTest; persistence: boolean; stopPropagation: boolean } = null;
  private static nextDoubleClick: { callback: OnMapClick; persistence: boolean; stopPropagation: boolean } = null;
  private static hitTestNextDoubleClick: { callback: OnMapHitTest; persistence: boolean; stopPropagation: boolean } = null;

}

export interface OnComponentCreated {
  (component: Vue): void;
}
