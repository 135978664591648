import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ModalHelper } from "@/components/modals/ModalHelper";
import Tutorial from "@/components/tutorial/tutorial.vue";


@Component({})
export default class LandingPage extends Vue {
  public hideLandingPage() {
    sessionStorage.setItem("showedLandingPage", "true");
    ModalHelper.HideModal();
  }

  public showTutorial() {
    ModalHelper.ShowModalAsync(Tutorial).then((completed) => { }, (e) => { });
  }
}
