import Vue from "vue";
import Component from "vue-class-component";
import { MapHelper } from "@/app_modules/MapHelper";
import { geometriesStoreGetters, geometriesStoreActions } from "@/store";
import { Esri } from "@/esriMap";

@Component({})
export default class SearchWidget extends Vue {
  public showSearch: boolean = false;
  public query: string = null;

  get countries() {
    return geometriesStoreGetters.getCountries();
  }

  public toggleSearch() {
    this.showSearch = !this.showSearch;
    if (!this.showSearch) {
      this.query = null;
      (this.$refs.countriesAutocomplete as any).inputValue = "";
    }
  }

  public async selectCountry(country: IFeatures<Countries>) {
    const currentMap = (await MapHelper.getCurrentMap());
    const geometry = await Esri.Geometry.Polygon(country.geometry);

    const attributes = {
      OBJECTID: country.attributes.OBJECTID,
      iso3: country.attributes.ISO3,
      name: country.attributes.FULL_NAME || " ", /// !!! SEGNALARE ALLA BETTA CHE C'è UN FULL_NAME VUOTO !!!
      lastValue: null,
      lastValueCompare: null,
      data: null,
      dataCompare: null,
    };
    const graphic = await Esri.Graphic({ attributes, geometry });
    geometriesStoreActions.setSelectedCountry(graphic);


    await currentMap.mapView.goTo({
      target: geometry.extent.expand(1.2),
    }, {
      animate: true,
      duration: 2000,
      easing: "in-out-expo",
    });
    this.showSearch = false;
  }
}
