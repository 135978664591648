import Vue from "vue";
import Component from "vue-class-component";
import { Esri } from "@/esriMap";
import { MapHelper } from "@/app_modules/MapHelper";
import { HomeWidget, FullExtentWidget, SearchWidget, DatasetWidget, OtherWidget } from "../widgets";
import { configurationsStoreGetters } from "@/store";
import { Widgets } from "@/esriMap/src/Widgets";

@Component({})
export default class Map extends Vue {
  private map: __esri.Map;
  private mapView: __esri.MapView;
  private center: number[] = [0, 0];
  private zoom: number = 2;

  public mounted() {
    this.inizializeMap();
  }

  public beforeDestroy() {
    MapHelper.clearMap();
  }

  private async inizializeMap() {
    const baseMapLayer = await Esri.Layers.VectorTileLayer({
      url: "https://tiles.arcgis.com/tiles/p26LyN1voRs8ixdR/arcgis/rest/services/UNAIDS_KP_ATLAS_BASEMAP_WGS84_Gray_Canvas/VectorTileServer"
    });

    const customBasemap = await Esri.Basemap({
      baseLayers: [baseMapLayer],
      title: "Custom Basemap",
      id: "customBasemap",
      thumbnailUrl: "images/basemap.png",
    });

    const apiKey = configurationsStoreGetters.config().arcgisApiKey;
    if (!apiKey) {
      console.warn('--- no api key has been set to access a resource or service of ArcGis ---');
    } else {
      const esriConfig = await Esri.config() as any;
      esriConfig.apiKey = apiKey;
    }

    this.map = await Esri.Map({
      basemap: customBasemap,
    });

    this.mapView = await Esri.Views.MapView({
      map: this.map,
      container: "mapView",
      constraints: {
        snapToZoom: false,
        minZoom: 2,
      },
      highlightOptions: {
        color: [99, 205, 246, 1],
        haloOpacity: 0.125,
        fillOpacity: 0.25,
      },
      zoom: this.zoom,
      center: this.center,
      spatialReference: { wkid: 4326 }
    } as __esri.MapViewProperties);

    this.mapView.ui.remove("attribution");
    this.mapView.ui.remove("zoom");

    this.mapView.when(async () => {
      MapHelper.setCurrentMap(this);
      this.mapView.ui.add(MapHelper.CreateWidgetTemplate(HomeWidget).$el as HTMLElement, "top-left");
      this.mapView.ui.add(MapHelper.CreateWidgetTemplate(FullExtentWidget).$el as HTMLElement, "top-left");
      this.mapView.ui.add(MapHelper.CreateWidgetTemplate(SearchWidget).$el as HTMLElement, "top-left");
      this.mapView.ui.add(MapHelper.CreateWidgetTemplate(DatasetWidget).$el as HTMLElement, "top-left");
      this.mapView.ui.add(MapHelper.CreateWidgetTemplate(OtherWidget).$el as HTMLElement, "top-left");
      // Esri.WidgetsFactory.Search(this.mapView, "top-right");
      // Esri.WidgetsFactory.LayerList(this.mapView, "top-right");
      Esri.WidgetsFactory.Legend(this.mapView, "bottom-right");
    });
  }

  public async goToHome() {
    this.mapView.goTo({
      target: await Esri.Geometry.Point({ longitude: this.center[0], latitude: this.center[1], spatialReference: { wkid: 4326 } } as __esri.PointProperties),
      zoom: this.zoom,
    }, {
      animate: true,
      duration: 2000,
      easing: "ease-in-out",
    });
  }

  public async CustomLegend(mapView: __esri.View, position: string = "bottom-right"): Promise<__esri.Legend> {
    const result = await new Widgets().Legend({ view: mapView });
    mapView.ui.add(result, { position } as any);
    return result;
  }
}
