import Vue from "vue";
import Component from "vue-class-component";
import { MapHelper } from "@/app_modules/MapHelper";
import { configurationsStoreGetters } from "@/store";

@Component({})
export default class DatasetWidget extends Vue {

  get fullDatasetSource(): { url: string; label: string } {
    const config = configurationsStoreGetters.config();
    if (config) {
      return config.abstract.fullDataset;
    }
    return { url: "", label: "" };
  }
}
